import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-510a8d81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-players-profile-root-user-information-form-update-information" }
const _hoisted_2 = { class: "action" }
const _hoisted_3 = { class: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Edit = _resolveComponent("Edit")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        type: "primary",
        class: "el-button--grey el-button--square",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDialogVisible = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Edit)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      title: "Uredi",
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_form, {
            model: _ctx.formData,
            ref: "formComponent",
            onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "Ime",
                prop: "content.name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.formData.content.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.content.name) = $event)),
                    placeholder: "npr. Ivan"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Prezime",
                prop: "content.surname"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.formData.content.surname,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.content.surname) = $event)),
                    placeholder: "npr. Horvat"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Dominantna noga",
                prop: "content.foot"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.formData.content.foot,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.content.foot) = $event)),
                    placeholder: "npr. Desna"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Rođendan",
                prop: "content.birthday"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, {
                    modelValue: _ctx.formData.content.birthday,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.content.birthday) = $event)),
                    type: "date",
                    format: "DD.MM.YYYY.",
                    placeholder: "Odaberite datum"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Pozicija",
                prop: "content.position"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.formData.content.position,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.content.position) = $event)),
                    placeholder: "Odaberite"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_option, {
                        label: "Golman",
                        value: "goalkeeper"
                      }),
                      _createVNode(_component_el_option, {
                        label: "Branič",
                        value: "defender"
                      }),
                      _createVNode(_component_el_option, {
                        label: "Veznjak",
                        value: "midfielder"
                      }),
                      _createVNode(_component_el_option, {
                        label: "Napadač",
                        value: "striker"
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Visina",
                prop: "content.height"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, {
                    modelValue: _ctx.formData.content.height,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.content.height) = $event)),
                    "controls-position": "right",
                    min: 160,
                    max: 220
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Težina",
                prop: "content.weight"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, {
                    modelValue: _ctx.formData.content.weight,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.content.weight) = $event)),
                    "controls-position": "right",
                    min: 50,
                    max: 120
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    class: "el-button--primary el-button--block",
                    "native-type": "submit",
                    loading: _ctx.isLoading,
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.validateForm()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Spremi ")
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "onSubmit"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}